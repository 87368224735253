import React from "react"
import { graphql } from "gatsby"
import dayjs from "dayjs"

import { useAnalytics } from "../hooks"

import { Layout, Header, SEO, TextCard, RichText } from "../components"
import { legalPageSlug } from "../utilities/createSlug"

interface Props {
  data: {
    contentfulLegalPages: {
      contentful_id: string
      content: {
        raw: string
      }
      updatedAt: Date
      heading: string
      slug: string
      seo: {
        description: {
          description: string
        }
        socialImage: {
          file: {
            url: string
          }
        }
        title: string
      }
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: legalPageSlug(data.contentfulLegalPages.slug),
  })

  const url =
    process.env.GATSBY_SITE_URL + legalPageSlug(data.contentfulLegalPages.slug)

  return (
    <Layout>
      <SEO
        title={data.contentfulLegalPages.seo.title}
        description={data.contentfulLegalPages.seo.description.description}
        image={data.contentfulLegalPages.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={data.contentfulLegalPages.heading}
        textAlignment="center"
      >
        <p>
          Last updated:{" "}
          {dayjs(data.contentfulLegalPages.updatedAt).format("DD/MM/YYYY")}
        </p>
      </Header>
      <main className="backgroundGreyLightSuper">
        <div className="wrapper padded">
          <TextCard styles="content legalPage">
            <RichText content={data.contentfulLegalPages.content} />
          </TextCard>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query getLegalPage($contentful_id: String!) {
    contentfulLegalPages(contentful_id: { eq: $contentful_id }) {
      contentful_id
      content {
        raw
      }
      slug
      updatedAt
      heading
      seo {
        description {
          description
        }
        socialImage {
          file {
            url
          }
        }
        title
      }
    }
  }
`
